import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab, useMediaQuery } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material";

const AntTabs = withStyles({
  root: { minHeight: 30 },
  indicator: {
    background: "transparent",
  },
})(Tabs);
const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 70,
    fontWeight: 500,
    marginRight: 5,
    padding: 4,
    minHeight: 60,
    lineHeight: 1,
    "&:hover": {
      color: "#40a9ff",
      opacity: 1,
    },
    "&$selected": {
      backgroundColor: "white",
      boxShadow: "0px 1.79956px 4.4989px 0px rgba(0, 0, 0, 0.10)",
      "& .MuiTab-wrapper": {
        fontWeight: 700,
        color: "#8A6FFD",
      },
    },
    "&:focus": {
      color: "#40a9ff",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div style={{ overflow: "hidden" }}>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "rgba(238, 238, 238, 0.40)",

    marginLeft: "auto",
    marginRight: "auto",
  },
  tabs: {
    color: "#383538",
    overflow: "hidden",
    borderRadius: 3,
    margin: 4,
    backgroundColor: "rgba(238, 238, 238, 0.40)",
    borderRadius: 56,
  },
  tab: (props) => ({
    fontSize: 16,
    borderRadius: 3,
    margin: 4,
    borderRadius: 56,
    height: 60,
    border: "0px",
    "& .MuiTab-wrapper": {
      fontWeight: 700,
      color: "#383538",
    },
    [props.breakpoints.down("md")]: {
      fontSize: 13,
      height: 35,
      minHeight: 35,
    },
  }),
  metaTabs: {
    color: "#383538",
    overflow: "hidden",

    borderRadius: 3,
    margin: 3,
  },
  metaTab: {
    fontSize: 20,
    fontWeight: 700,
    borderRadius: 3,
    margin: 3,

    textTransform: "capitalize",
    border: "0px",
    "& .MuiTab-wrapper": {
      fontWeight: 700,
      color: "#383538",
    },
  },
}));

export default function CustomTabs({
  tabs,
  initIndex = 0,
  tabIndex,
  handleChange,
  metaform,
  sm,
  maxWidth,
}) {
  const [value, setValue] = useState(parseInt(initIndex));
  const customTheme = useTheme();
  const computer = useMediaQuery(customTheme.breakpoints.up("sm"));
  const classes = useStyles(customTheme);

  return (
    <div
      style={{ maxWidth: maxWidth || "100%", margin: "0 auto", marginTop: 22 }}
    >
      <div position="static" className={classes.tabs}>
        <AntTabs
          // TabIndicatorProps={{ style: { background: "transparent" } }}
          value={typeof tabIndex === "number" ? tabIndex : value}
          onChange={handleChange}
          indicatorColor="primary"
          variant="fullWidth"
        >
          {tabs.map((tab, index) => {
            // let color =
            //   tabIndex == index ? theme.palette.background.primary : "";
            return (
              <AntTab
                key={tab.label}
                // style={{ backgroundColor: color }}
                className={`${classes.tab}`}
                label={tab.label}
                disabled={tab.disabled}
                {...a11yProps(index)}
              />
            );
          })}
        </AntTabs>{" "}
      </div>

      {tabs.map((tab, index) => {
        return (
          <TabPanel
            key={index}
            value={typeof tabIndex === "number" ? tabIndex : value}
            index={index}
          >
            {tab.content}
          </TabPanel>
        );
      })}
    </div>
  );
}
