import styled from "@emotion/styled/macro";

export const StyledButton = styled("a")(({ theme, white }) => ({
  color: white ? "#fff" : "#383538",
  width: "44px",
  height: "44px",
  borderRadius: "50px",
  marginRight: "16px",
  paddingTop: 3,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    background: white ? "#0096FF" : "#7B61FF",
    color: white ? "#383538" : "#fff",
  },
}));
