import React from "react";
import { RouterProvider } from "react-router-dom";
import router from "./route";
import { hot, setConfig } from "react-hot-loader";
import { SnackbarProvider } from "notistack";
import { Helmet } from "react-helmet";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { enMessages, zhTWMessages } from "locales";
import { IntlProvider } from "react-intl";

setConfig({
  logLevel: "debug",
});

const gtmParams = {
  id: "GTM-MGQKWWV9",
};

const renderMetadata = (
  title = "MetaCRM | Web3 Marketing Analytics & A.I. Customer Service",
  desc = "Utilize Blockchain data to grow your business faster and serve your community better with MetaCRM. Schedule a demo today!",
  url,
  imageURL
) => {
  if (!url) {
    url = window.location.href;
  }
  if (!imageURL) {
    imageURL = window.location.origin + require("assets/img/defaultBanner.png");
  }
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="fragment" content="!" />
      <meta itemprop="name" content={title} />
      <meta itemprop="description" content={desc} />
      <meta itemprop="image" content={imageURL} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content={imageURL} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:image" content={imageURL} />
      <meta name="description" content={desc} />
    </Helmet>
  );
};

function Root() {
  const defaultMessages = {
    en: enMessages,
    "zh-TW": zhTWMessages,
  };

  return (
    <GTMProvider state={gtmParams}>
      {renderMetadata(
        "MetaCRM | Web3 Marketing Analytics & A.I. Customer Service",
        "Utilize Blockchain data to grow your business faster and serve your community better with MetaCRM. Schedule a demo today!",
        window.location.href,
        null
      )}
      <IntlProvider
        locale={"en"}
        defaultLocale="en"
        messages={defaultMessages["en"]}
      >
        <SnackbarProvider>
          <RouterProvider router={router} />
        </SnackbarProvider>
      </IntlProvider>
    </GTMProvider>
  );
}

export default hot(module)(Root);
