import React, { useRef, useState, useEffect } from "react";
import { useTheme } from "@mui/styles";
import {
  Slide,
  Grid,
  useMediaQuery,
  Fade,
  Collapse,
  Grow,
} from "@mui/material";
import _ from "lodash";
import LandingOurPartners from "../components/LandingOurPartners/LandingOurPartners";
import {
  StyledLandingWelcomContainer,
  StyledCloud,
  StyledMainContainer,
  StyledField,
  StyledHeader,
  StyledSubHeader,
  StyledCustomerListImg,
  StyledModuleImg,
  StyledMarketingLeanerText,
  StyledPreferredToolContainer,
  StyledPreferredTool,
  StyledLandingRevolutionContainer,
  LinearLine,
} from "./Home.styles";
import { XLPrimaryButton } from "components/CustomButtons/CustomButton.style";
import IconIcMailchimp from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcMailchimp";
import SwiperCore, {
  Navigation,
  Pagination,
  Controller,
  Thumbs,
  Autoplay,
} from "swiper";
import "swiper/swiper-bundle.css";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import Revolution from "features/home/components/Revolution";
SwiperCore.use([Navigation, Pagination, Controller, Thumbs, Autoplay]);

const chainList = [
  {
    logo_url: require("assets/img/eth.svg").default,
  },
  {
    logo_url: require("assets/img/bsc.svg").default,
  },
  {
    logo_url: require("assets/img/arbitrum.svg").default,
  },
  {
    logo_url: require("assets/img/optimism.svg").default,
  },
  {
    logo_url: require("assets/img/polygon.svg").default,
  },
  {
    logo_url: require("assets/img/vector.svg").default,
  },
  {
    logo_url: require("assets/img/solana.png"),
  },
  {
    logo_url: require("assets/img/manta.svg").default,
  },
];

const Marketing = () => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const computer = useMediaQuery(theme.breakpoints.up("sm"));
  const [enterTime, setEnterTime] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);

  const setCurrentYPosition = () => {
    setScrollPosition(window.scrollY);
  };
  useEffect(() => {
    const timer = setInterval(() => {
      setEnterTime(enterTime + 1);
    }, 1000);
    return () => clearTimeout(timer);
  }, [enterTime]);

  useEffect(() => {
    window.addEventListener("scroll", setCurrentYPosition);
    return () => {
      window.removeEventListener("scroll", setCurrentYPosition);
    };
  }, []);

  return (
    <div style={{ overflow: "hidden" }}>
      <Helmet>
        <title>MetaCRM | Web3 Marketing & Analytics</title>
      </Helmet>
      <StyledMainContainer
        computer={computer}
        style={{
          paddingTop: computer ? "150px" : "48px",
          textAlign: "center",
          backgroundImage: `url(${require("assets/img/marketingMainBg.png")})`,
        }}
      >
        <Slide direction="up" in={true} timeout={1000}>
          <div>
            <StyledCloud
              src={require("assets/img/marketingTitle.png")}
              style={{ ...(!computer && { maxWidth: "80%" }) }}
            />
            <Box
              color={(theme) => theme.customColors.grey[500]}
              sx={{ fontSize: "18px" }}
              mb={2}
              mt="46px"
            >
              {formatMessage({ id: "global.supported.chains" })} :
            </Box>
            <Box>
              {chainList.map((chain, idx) => (
                <Box
                  component={"img"}
                  sx={{ height: "32px", width: "32px", mr: 1 }}
                  src={chain.logo_url}
                ></Box>
              ))}
            </Box>
            <StyledField sx={{ paddingTop: "32px" }}>
              <div style={{ textAlign: "center" }}>
                <StyledMarketingLeanerText
                  style={{ fontSize: computer ? 28 : 16, fontWeight: 300 }}
                >
                  {formatMessage({
                    id: "marketingPage.hero.description.line1",
                  })}
                  ,{computer && <br />}
                </StyledMarketingLeanerText>
                <StyledMarketingLeanerText
                  style={{ fontSize: computer ? 28 : 16, fontWeight: 300 }}
                >
                  {formatMessage({
                    id: "marketingPage.hero.description.line2",
                  })}
                </StyledMarketingLeanerText>
              </div>
            </StyledField>{" "}
          </div>
        </Slide>

        <StyledField>
          <StyledCustomerListImg
            src={require("assets/img/marketingPushPerformance.png")}
          />
        </StyledField>
        <StyledField>
          <Grow
            in={scrollPosition > 300}
            style={{ transformOrigin: "bottom center" }}
            timeout={1500}
          >
            <div>
              <StyledHeader>
                {formatMessage({ id: "global.status.trustedBy" })}
              </StyledHeader>
              <LandingOurPartners />
            </div>
          </Grow>
        </StyledField>
        <StyledField
          computer={computer}
          style={{ paddingTop: computer ? 50 : 0 }}
        >
          <Grow
            in={scrollPosition > (computer ? 900 : 600)}
            style={{ transformOrigin: "top center" }}
            timeout={2000}
          >
            <div style={{ maxWidth: 1068, margin: "0 auto" }}>
              <StyledHeader>
                {formatMessage({
                  id: "marketingPage.content.attributionInfo.title",
                })}
              </StyledHeader>
              {computer && (
                <StyledSubHeader style={{ marginTop: 24 }}>
                  {formatMessage(
                    {
                      id: "marketingPage.content.attributionInfo.description",
                    },
                    {
                      br: computer ? <br /> : " ",
                    }
                  )}
                </StyledSubHeader>
              )}

              <StyledModuleImg
                computer={computer}
                style={{ marginTop: computer ? 83 : 40 }}
                src={require("assets/img/marketingProfileAnalysis.png")}
              />
            </div>
          </Grow>
        </StyledField>

        <StyledField computer={computer}>
          <Grow
            in={scrollPosition > 800}
            style={{ transformOrigin: "top right" }}
            timeout={1000}
          >
            <Grid container alignItems={"center"}>
              <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
                <StyledModuleImg
                  style={{ height: computer ? 360 : 330 }}
                  computer={computer}
                  src={require("assets/img/marketingSegmentUsers.svg").default}
                />
              </Grid>
              <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                <div
                  style={{ maxWidth: 600, textAlign: "left", margin: "0 auto" }}
                >
                  <StyledHeader
                    sx={{ textAlign: computer ? "left" : "center" }}
                  >
                    {formatMessage({
                      id: "marketingPage.content.outreachInfo.title.line1",
                    })}{" "}
                    <span style={{ background: "#D8FFF6" }}>
                      {formatMessage({
                        id: "marketingPage.content.outreachInfo.title.line2",
                      })}
                    </span>
                  </StyledHeader>
                  {computer && (
                    <StyledSubHeader style={{ marginTop: 26, maxWidth: 500 }}>
                      {formatMessage({
                        id: "marketingPage.content.outreachInfo.description",
                      })}
                    </StyledSubHeader>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grow>
        </StyledField>
        <StyledPreferredToolContainer>
          <div>
            <Box
              sx={{ maxWidth: 850, margin: "0 auto" }}
              px={computer ? "0" : "20px"}
            >
              <StyledHeader>
                {formatMessage({
                  id: "marketingPage.content.integrateInfo.title",
                })}
              </StyledHeader>
              {computer && (
                <StyledSubHeader style={{ marginTop: 25 }}>
                  {formatMessage(
                    {
                      id: "marketingPage.content.integrateInfo.description",
                    },
                    { br: computer ? <br /> : " " }
                  )}
                </StyledSubHeader>
              )}
            </Box>

            <div
              style={{
                marginTop: 50,
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <StyledPreferredTool computer={computer}>
                <IconIcMailchimp
                  width={computer ? "60px" : "46px"}
                  height={computer ? "60px" : "46px"}
                />
                <div>
                  {formatMessage({
                    id: "marketingPage.content.mailchimpInfo.title",
                  })}
                </div>
              </StyledPreferredTool>
              <StyledPreferredTool computer={computer}>
                <img src={require("assets/img/ic_ga.svg").default} />
                <div>
                  {formatMessage({
                    id: "marketingPage.content.googleAnalyticsInfo.title",
                  })}
                </div>
                <div className="comingSoon">
                  {formatMessage({ id: "global.status.comingSoon" })}
                </div>
              </StyledPreferredTool>
              <StyledPreferredTool computer={computer}>
                <img src={require("assets/img/ic_blockscanChat.svg").default} />
                <div>
                  {formatMessage({
                    id: "marketingPage.content.BlockscanChatInfo.title",
                  })}
                </div>
                <div className="comingSoon">
                  {formatMessage({ id: "global.status.comingSoon" })}
                </div>
              </StyledPreferredTool>

              <StyledPreferredTool computer={computer}>
                <img src={require("assets/img/ic_debank.svg").default} />
                <div>
                  {formatMessage({
                    id: "marketingPage.content.debankInfo.title",
                  })}
                </div>
                <div className="comingSoon">
                  {formatMessage({ id: "global.status.comingSoon" })}
                </div>
              </StyledPreferredTool>
            </div>
          </div>
        </StyledPreferredToolContainer>
      </StyledMainContainer>

      <StyledLandingWelcomContainer
        computer={computer}
        style={{
          backgroundImage: `url(${require("assets/img/marketingBg.png")})`,
          padding: computer ? "126px 20px" : "80px 20px 60px",
        }}
      >
        <Grow
          in={scrollPosition > (computer ? 2400 : 1800)}
          style={{ transformOrigin: "bottom center" }}
          timeout={1000}
        >
          <div>
            <StyledMarketingLeanerText>
              {formatMessage({ id: "marketingPage.footer.title.line1" })}{" "}
              {computer && <br />}{" "}
            </StyledMarketingLeanerText>
            <StyledMarketingLeanerText>
              {formatMessage({ id: "marketingPage.footer.title.line2" })}
            </StyledMarketingLeanerText>
            {computer && (
              <StyledSubHeader style={{ maxWidth: 480, margin: "17px auto" }}>
                {formatMessage({ id: "marketingPage.footer.description" })}
              </StyledSubHeader>
            )}
            <XLPrimaryButton
              style={{ display: "block", margin: "24px auto" }}
              onClick={() => {
                window.open("https://app.metacrm.inc/trial/marketing");
              }}
            >
              {formatMessage({ id: "global.btn.getStarted" })}
            </XLPrimaryButton>
          </div>
        </Grow>
      </StyledLandingWelcomContainer>

      <Revolution
        growIn={scrollPosition > (computer ? 3000 : 2100)}
        scrollPosition={scrollPosition}
        computer={computer}
        title={formatMessage({ id: "marketingPage.content.crossChain.title" })}
        description={formatMessage({
          id: "marketingPage.content.crossChain.description",
        })}
        image={require("assets/img/revolution-solana.png")}
        background="linear-gradient(180deg,#C8A6FF -135%,rgba(255, 255, 255, 0) 27%, #FFA6F1 250%)"
      ></Revolution>
    </div>
  );
};

export default Marketing;
