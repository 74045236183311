import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";

import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";

export const StyledDialog = styled(Dialog, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  "& .MuiPaper-root": {
    maxWidth: "836px",
    borderRadius: "10px",
    boxShadow: `0px 4px 10px 0px #0000001A`,
    padding: "66px 5% 80px",
    margin: 10,
    [theme.breakpoints.down("md")]: {
      padding: "66px 5%",
    },
  },
}));

export const StyledTrialDialogTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "24px",
  fontWeight: 900,
  marginBottom: "12px",
  textAlign: "center",
  color: theme.customColors.grey[700],
}));

export const StyledTrialDialogSubTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  fontSize: "18px",
  color: theme.customColors.grey[700],
  textAlign: "center",
  maxWidth: "492px",
}));

export const StyledHoverShow = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.purple[500],
  position: "absolute",
  bottom: "-48px",
  display: "none",
}));

export const StyledLinkTrail = styled(Link, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, computer }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  boxShadow: `0px 3.042876958847046px 7.607192516326904px 0px #0000001A`,
  maxWidth: "350px",
  margin: "0 auto",
  borderRadius: "20px",
  padding: "24px 24px 35px",
  border: `2px solid transparent`,
  "&:hover": {
    border: `2px solid ${theme.customColors.purple[500]}`,
    [StyledHoverShow]: {
      ...(computer && {
        display: "block",
      }),
    },
  },
}));

export const StyledImg = styled("img", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  height: "190px",
  marginBottom: "14px",
}));

export const StyledLinkTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[700],
  fontWeight: 700,
  fontSize: "20px",
  marginBottom: "12px",
}));

export const StyledLinkText = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: theme.customColors.grey[600],
  fontSize: "18px",
  fontWeight: 400,
  textAlign: "center",
  minHeight: 105,
}));
