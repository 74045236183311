import React from "react";
import { StyledFooterEmail } from "./Footer.styles";

const FooterEmail = ({ computer, Icon, ...otherProps }) => {
  return (
    <StyledFooterEmail
      component="a"
      computer={computer}
      href="mailto: bd@metacrm.inc"
      {...otherProps}
    >
      {Icon && <Icon />}
      <div>bd@metacrm.inc</div>
    </StyledFooterEmail>
  );
};

export default FooterEmail;
