import React from "react";
import { StyledButton } from "./socialIcons.styles";

const SocialIcons = ({ center }) => {
  return (
    <div className={center ? "row" : "startRow"}>
      <StyledButton href="https://twitter.com/metacrm_inc" target="_blank">
        <i className="meta-crm-icon-ic_x-1 font-size-20" />
      </StyledButton>
      <StyledButton href="http://bit.ly/3uR9OBr" target="_blank">
        <i className="meta-crm-icon-ic_telegram font-size-16" />
      </StyledButton>
      <StyledButton href="https://discord.gg/EVuwAzhKxu" target="_blank">
        <i className="meta-crm-icon-ic_discord font-size-24" />
      </StyledButton>

      <StyledButton href="https://www.youtube.com/@MetaCRM_inc" target="_blank">
        <i className="meta-crm-icon-ic_youtube-1 font-size-20" />
      </StyledButton>
    </div>
  );
};

export default SocialIcons;
