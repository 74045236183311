import React, { useEffect, useState } from "react";

import {
  StyledDialog,
  StyledTrialDialogTitle,
  StyledTrialDialogSubTitle,
  StyledLinkTrail,
  StyledImg,
  StyledLinkTitle,
  StyledLinkText,
  StyledHoverShow,
} from "./TrialDialog.styles";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { useIntl } from "react-intl";

const TrialDialog = ({ open, onClose }) => {
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("md"));
  const { formatMessage } = useIntl();
  return (
    <StyledDialog open={open} onClose={onClose} scroll="body">
      <StyledTrialDialogTitle>Choose Your Role</StyledTrialDialogTitle>
      <Box display="flex" justifyContent="center">
        <StyledTrialDialogSubTitle>
          To lead you to the solution that fits your needs
        </StyledTrialDialogSubTitle>
      </Box>

      <Grid
        container
        mt="26px"
        alignItems="stretch"
        justifyContent="center"
        spacing={3}
      >
        <Grid item xs={12} md={6}>
          <StyledLinkTrail
            to="https://app.metacrm.inc/trial/marketing"
            target="_blank"
            computer={computer}
          >
            <StyledImg
              src={require("assets/img/marketing-link.svg").default}
            ></StyledImg>
            <StyledLinkTitle>
              {formatMessage({ id: "popup.onboarding.marketingRole.title" })}
            </StyledLinkTitle>
            {computer && (
              <StyledLinkText>
                {formatMessage({
                  id: "popup.onboarding.marketingRole.description",
                })}
              </StyledLinkText>
            )}
            <StyledHoverShow>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <i className="meta-crm-icon-ic_arrowLineUp font-size-16" />
              </Box>
              <Box>MetaCRM for Marketing</Box>
            </StyledHoverShow>
          </StyledLinkTrail>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledLinkTrail
            to="https://app.metacrm.inc/trial/service"
            target="_blank"
            computer={computer}
          >
            <StyledImg
              src={require("assets/img/service-link.svg").default}
            ></StyledImg>
            <StyledLinkTitle>
              {" "}
              {formatMessage({ id: "popup.onboarding.serviceRole.title" })}
            </StyledLinkTitle>
            {computer && (
              <StyledLinkText>
                {formatMessage({
                  id: "popup.onboarding.serviceRole.description",
                })}
              </StyledLinkText>
            )}
            <StyledHoverShow>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <i className="meta-crm-icon-ic_arrowLineUp font-size-16" />
              </Box>
              <Box>MetaCRM for Service</Box>
            </StyledHoverShow>
          </StyledLinkTrail>
        </Grid>
      </Grid>
    </StyledDialog>
  );
};

export default TrialDialog;
