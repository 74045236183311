import React from "react";
import styled from "@emotion/styled/macro";
import { keyframes } from "@emotion/react";

const Marquee = ({ images }) => {
  return (
    <StyledMarquee>
      <MarqueeContent imageCount={images.length}>
        {images.map((image, index) => (
          <MarqueeIcon key={index} src={image} alt={`icon-${index}`} />
        ))}
      </MarqueeContent>
    </StyledMarquee>
  );
};

export default Marquee;

// Styled Components

export const StyledMarquee = styled("div")(({ theme }) => ({
  width: "100%",
  overflow: "hidden",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  margin: "30px 0",
}));

const marqueeAnimation = keyframes`
  from { transform: translateX(0%); }
  to { transform: translateX(-100%); }
`;

export const MarqueeContent = styled("div")(({ theme, imageCount }) => ({
  display: "flex",
  flexWrap: "nowrap",
  animation: `${marqueeAnimation} 10s linear infinite`,
  animationDirection: "alternate",
}));

export const MarqueeIcon = styled("img")(({ theme }) => ({
  width: 100,
  height: 100,
  marginRight: 30,
  filter: "grayscale(100%)",
}));
