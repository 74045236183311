import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";

export const StyledLink = styled(Link, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, noMarginLeft }) => ({
  color: "#000000",
  textDecoration: "none",
  marginRight: "24px",
  fontWeight: 400,
  position: "relative",
  display: "inline-flex",
  alignItems: "center",
  height: "24px",
  "&:hover": {
    color: theme.customColors.purple[600],
  },
  "&:not(:first-child)": {
    marginLeft: "24px",
    ...(noMarginLeft && { marginLeft: 0 }),
  },
  "&:not(:last-child)::after": {
    content: '""',
    position: "absolute",
    right: "-24px",
    top: "50%",
    transform: "translateY(-50%)",
    height: "14px",
    width: "1px",
    backgroundColor: theme.customColors.grey[700],
  },
  "&.no-line::after": {
    content: "none",
  },
}));

export const StyledTitle = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({}) => ({
  display: "flex",
  alignItems: "center",
  color: "#383538",
  opacity: 0.5,
  marginTop: "40px",
  marginBottom: "21px",
}));

export const StyledFooterEmail = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, computer = true }) => ({
  color: "#383538",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  paddingBottom: 0,
  "&:hover": {
    color: theme.customColors.purple[600],
    "& svg": {
      fill: theme.customColors.purple[600],
    },
  },
  "& svg": {
    marginRight: "8px",
    fill: "#383538",
  },
}));
