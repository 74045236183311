import React, { useRef, useState, useEffect, useContext } from "react";
import { useTheme } from "@mui/styles";
import {
  Slide,
  Grid,
  useMediaQuery,
  Fade,
  Collapse,
  Grow,
} from "@mui/material";
import _ from "lodash";
import LandingOurPartners from "../components/LandingOurPartners/LandingOurPartners";
import {
  StyledLeanerText,
  StyledLandingWelcomContainer,
  StyledSubTitle,
  StyledCloud,
  StyledCloudContainer,
  UnderLine,
  StyledCloudCircle,
  StyledCloudInfo1,
  StyledCloudInfo2,
  StyledCloudInfo3,
  StyledBanner,
  StyledStartButtonContainer,
  StyledMainContainer,
  StyledField,
  StyledHeader,
  StyledSubHeader,
  StyledCustomerListImg,
  StyledModuleImg,
  StyledModuleField,
  StyledModuleIcon,
  StyledLearnMoreButtonContainer,
  StyledToolField,
  StyledToolIcons,
  StyledBigAlpaca,
  StyledBigAlpacaContainer,
  StyledChatIcon,
  StyledIdealIcon,
  StyledGlobalImg,
  StyledGlobalContainer,
  StyledGlobalToken,
  LinearLine,
  StyledProductHeader,
  LinearLoadingContainer,
  StyledRecomandImg,
  StyledBubble,
  StyledLandingFooterContainer,
  StyledLinearLine,
} from "./Home.styles";
import { XLPrimaryButton } from "components/CustomButtons/CustomButton.style";
import { VARIANT } from "@metacrm/metacrm-material-ui/dist/Button";
import CustomTabs from "components/CustomTabs/Tabs";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Controller,
  Thumbs,
  Autoplay,
} from "swiper";
import "swiper/swiper-bundle.css";
import { StyledCustomSwiperContainer } from "../components/LandingOurPartners/partner.styles";
import LinearLoading from "../components/LinearLoading";
import BubbleAnimation from "./BubbleAnimation";
import { Link } from "react-router-dom";
import { OpenTrialContext } from "./TrialDialog/OpenTrialContext";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import Lottie from "react-lottie";
import landingFlow from "assets/animation/landingFlow.json";

SwiperCore.use([Navigation, Pagination, Controller, Thumbs, Autoplay]);

const Home = () => {
  const { formatMessage } = useIntl();
  const [selectedModule, setSelectedModule] = useState(0);
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("md"));
  const [enterTime, setEnterTime] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { openTrial } = useContext(OpenTrialContext);
  const [linearLoading, setLinearLoading] = useState(false);
  const headerContainerRef = useRef(null);
  const cloudContainerRef = useRef(null);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: landingFlow,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const setCurrentYPosition = () => {
    setScrollPosition(window.scrollY);

    if (window.scrollY < 600) {
      setLinearLoading(false);
    }
  };
  useEffect(() => {
    const timer = setInterval(() => {
      setEnterTime(enterTime + 1);
    }, 1000);
    return () => clearTimeout(timer);
  }, [enterTime]);

  useEffect(() => {
    if (computer) {
      setLinearLoading(false);
    } else {
      setLinearLoading(true);
    }
  }, [computer]);

  useEffect(() => {
    window.addEventListener("scroll", setCurrentYPosition);
    return () => {
      window.removeEventListener("scroll", setCurrentYPosition);
    };
  }, []);

  const renderModuleIntro = () => {
    return (
      <Box style={{ marginTop: computer ? 61 : 12 }}>
        <Grid
          container
          sx={{ display: selectedModule === 0 ? "flex" : "none" }}
        >
          <Grid item xs={12} md={7} order={{ xs: 2, md: 1 }}>
            <StyledModuleImg
              computer={computer}
              src={require("assets/img/forMarketing.svg").default}
            />
            {!computer && (
              <StyledLearnMoreButtonContainer>
                <Link to={"/marketing"}>
                  <XLPrimaryButton>
                    {formatMessage({ id: "global.btn.learnMore" })}
                  </XLPrimaryButton>
                </Link>
              </StyledLearnMoreButtonContainer>
            )}
          </Grid>
          <Grid item xs={12} md={5} order={{ xs: 1, md: 2 }}>
            <StyledModuleField>
              {computer && (
                <StyledModuleIcon
                  src={require("assets/img/marketingIcon.svg").default}
                />
              )}

              {computer && (
                <StyledHeader>
                  {formatMessage({
                    id: "homePage.content.solutionsInfo.marketingOption",
                  })}
                </StyledHeader>
              )}
              <StyledSubHeader style={{ marginTop: 12 }}>
                {formatMessage(
                  {
                    id: "homePage.content.solutionsInfo.marketingDescription",
                  },
                  {
                    br: computer ? <br /> : " ",
                  }
                )}
              </StyledSubHeader>

              {computer && (
                <StyledLearnMoreButtonContainer>
                  <Link to={"/marketing"}>
                    <XLPrimaryButton>
                      {formatMessage({ id: "global.btn.learnMore" })}
                    </XLPrimaryButton>
                  </Link>
                </StyledLearnMoreButtonContainer>
              )}
            </StyledModuleField>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ display: selectedModule === 1 ? "flex" : "none" }}
        >
          <Grid item xs={12} md={5}>
            <StyledModuleField>
              {computer && (
                <StyledModuleIcon
                  src={require("assets/img/serviceIcon.svg").default}
                />
              )}

              {computer && (
                <StyledHeader>
                  {formatMessage({
                    id: "homePage.content.solutionsInfo.serviceOption",
                  })}
                </StyledHeader>
              )}
              <StyledSubHeader style={{ marginTop: 12 }}>
                {formatMessage(
                  {
                    id: "homePage.content.solutionsInfo.serviceDescription",
                  },
                  {
                    br: computer ? <br /> : " ",
                  }
                )}
              </StyledSubHeader>

              {computer && (
                <StyledLearnMoreButtonContainer>
                  <Link to={"/service"}>
                    <XLPrimaryButton>
                      {formatMessage({ id: "global.btn.learnMore" })}
                    </XLPrimaryButton>
                  </Link>
                </StyledLearnMoreButtonContainer>
              )}
            </StyledModuleField>
          </Grid>
          <Grid item xs={12} md={7}>
            <StyledModuleImg
              computer={computer}
              src={require("assets/img/forService.svg").default}
            />
            {!computer && (
              <StyledLearnMoreButtonContainer>
                <Link to={"/service"}>
                  <XLPrimaryButton>
                    {formatMessage({ id: "global.btn.learnMore" })}
                  </XLPrimaryButton>
                </Link>
              </StyledLearnMoreButtonContainer>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <StyledLandingWelcomContainer
        computer={computer}
        style={{ paddingTop: computer ? "64px" : "48px" }}
      >
        <div ref={headerContainerRef}>
          <Slide
            direction="up"
            in={true}
            timeout={1000}
            container={headerContainerRef.current}
          >
            <div>
              <StyledLeanerText
                style={{
                  fontSize: computer ? 50 : 32,
                  textAlign: computer ? "center" : "left",
                }}
              >
                {formatMessage({ id: "homePage.hero.title" })}
              </StyledLeanerText>
              <StyledSubTitle>
                {formatMessage(
                  { id: "homePage.hero.description" },
                  { br: !computer ? <br /> : " " }
                )}
              </StyledSubTitle>{" "}
            </div>
          </Slide>
        </div>
        <StyledCloudContainer ref={cloudContainerRef}>
          <Slide
            direction="up"
            in={enterTime >= 1 || scrollPosition > 600}
            timeout={500}
            container={cloudContainerRef.current}
          >
            <Box>
              <Lottie
                options={defaultOptions}
                width={computer ? "50%" : "80%"}
              />
            </Box>
          </Slide>

          <Slide
            direction="right"
            in={enterTime > 1 || scrollPosition > 600}
            timeout={1500}
            container={cloudContainerRef.current}
          >
            <StyledCloudInfo1
              computer={computer}
              src={require("assets/img/cloudInfo1.png")}
            />
          </Slide>
          <Slide
            direction="left"
            in={enterTime > 1 || scrollPosition > 600}
            timeout={1500}
            container={cloudContainerRef.current}
          >
            <StyledCloudInfo2
              computer={computer}
              src={require("assets/img/cloudInfo2.svg").default}
            />
          </Slide>
          <Slide
            direction="right"
            in={enterTime > 1 || scrollPosition > 600}
            timeout={1500}
            container={cloudContainerRef.current}
          >
            <StyledCloudInfo3
              computer={computer}
              src={require("assets/img/cloudInfo3.svg").default}
            />
          </Slide>
        </StyledCloudContainer>
        <StyledStartButtonContainer
          style={{
            marginTop: computer ? 50 : 120,
            marginBottom: computer ? 100 : 26,
          }}
        >
          <Slide
            direction="up"
            in={enterTime > 1 || scrollPosition > 600}
            timeout={1000}
          >
            <XLPrimaryButton onClick={openTrial}>
              {formatMessage({ id: "global.btn.getStarted" })}
            </XLPrimaryButton>
          </Slide>
        </StyledStartButtonContainer>
        {Array.from({ length: 50 }).map((_, index) => (
          <BubbleAnimation key={index} />
        ))}
        <Grow
          in={scrollPosition > 400}
          style={{ transformOrigin: "bottom center" }}
          timeout={1000}
        >
          <StyledBanner
            style={{
              padding: "120px 0",
              zIndex: 100,
              paddingBottom: computer ? 120 : 10,
            }}
          >
            <StyledLeanerText style={{ width: "985px", margin: "0 auto" }}>
              {formatMessage({ id: "homePage.header.subTitle" })}
            </StyledLeanerText>
          </StyledBanner>
        </Grow>
      </StyledLandingWelcomContainer>
      <StyledMainContainer>
        <StyledField computer={computer}>
          <Grow
            in={scrollPosition > (computer ? 800 : 500)}
            style={{ transformOrigin: "top center" }}
            timeout={2000}
          >
            <div>
              <StyledHeader>
                {formatMessage({ id: "homePage.content.allProductInfo.title" })}
              </StyledHeader>
              <StyledSubHeader>
                {formatMessage({
                  id: "homePage.content.allProductInfo.description",
                })}
              </StyledSubHeader>
            </div>
          </Grow>

          <StyledToolField>
            <LinearLoadingContainer>
              {scrollPosition > 900 && computer && (
                <LinearLoading setLinearLoading={setLinearLoading} />
              )}
            </LinearLoadingContainer>
            <Grow
              in={scrollPosition > (computer ? 900 : 500)}
              style={{ transformOrigin: "bottom center" }}
              timeout={2000}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Box>
                  <StyledToolIcons src={require("assets/img/tool1.png")} />
                </Box>
                <Box>
                  <StyledToolIcons src={require("assets/img/tool2.png")} />
                </Box>
                <Box>
                  <StyledToolIcons src={require("assets/img/tool3.png")} />
                </Box>
              </Box>
            </Grow>
            {computer ? (
              <StyledBigAlpacaContainer>
                <Grow
                  in={scrollPosition > 500}
                  style={{ transformOrigin: "bottom center" }}
                  timeout={800}
                >
                  <StyledBigAlpaca
                    src={require("assets/img/bigAlpaca.svg").default}
                  />
                </Grow>
                <Fade in={linearLoading || !computer} timeout={200}>
                  <StyledChatIcon
                    src={require("assets/img/chat.svg").default}
                    computer={computer}
                  />
                </Fade>
                <Fade in={linearLoading || !computer} timeout={200}>
                  <StyledIdealIcon
                    computer={computer}
                    src={require("assets/img/ideal.svg").default}
                  />
                </Fade>
              </StyledBigAlpacaContainer>
            ) : (
              <Box mt="10px" mb="10px">
                <Grow
                  in={scrollPosition > 500}
                  style={{ transformOrigin: "bottom center" }}
                  timeout={800}
                >
                  <Box
                    component={"img"}
                    width={"100%"}
                    src={require("assets/img/bigAlpacaMobile.png")}
                  />
                </Grow>
              </Box>
            )}

            <Fade in={linearLoading || !computer} timeout={200}>
              <StyledLeanerText
                style={{
                  fontSize: computer ? 28 : 16,
                  paddingLeft: computer ? 10 : 0,
                }}
              >
                {formatMessage({
                  id: "homePage.content.allProductInfo.subTitle",
                })}
              </StyledLeanerText>
            </Fade>
          </StyledToolField>
        </StyledField>
        <StyledField computer={computer}>
          <Grow
            in={scrollPosition > (computer ? 1900 : 1200)}
            style={{ transformOrigin: "top center" }}
            timeout={2000}
          >
            <div>
              <StyledHeader>
                {formatMessage({ id: "homePage.content.solutionsInfo.title" })}
              </StyledHeader>
              <CustomTabs
                tabIndex={selectedModule}
                maxWidth={800}
                handleChange={(e, newValue) => setSelectedModule(newValue)}
                tabs={[
                  {
                    label: (
                      <Box display={"flex"} alignItems={"center"}>
                        <i
                          className="meta-crm-icon-ic_tracking font-size-18"
                          style={{
                            marginRight: "2.5px",
                          }}
                        />
                        {formatMessage({
                          id: "homePage.content.solutionsInfo.marketingOption",
                        })}
                      </Box>
                    ),
                  },
                  {
                    label: (
                      <Box display={"flex"} alignItems={"center"}>
                        <i
                          className="meta-crm-icon-ic_service font-size-16"
                          style={{
                            marginRight: "2.5px",
                          }}
                        />
                        {formatMessage({
                          id: "homePage.content.solutionsInfo.serviceOption",
                        })}
                      </Box>
                    ),
                  },
                ]}
              />
            </div>
          </Grow>
          <Slide
            direction={"left"}
            in={scrollPosition > (computer ? 1950 : 1250)}
            timeout={1000}
          >
            {renderModuleIntro()}
          </Slide>
        </StyledField>

        <StyledField computer={computer}>
          <Grow
            in={scrollPosition > (computer ? 2800 : 1800)}
            style={{ transformOrigin: "top center" }}
            timeout={1000}
          >
            <div>
              <StyledHeader>
                {formatMessage({ id: "homePage.content.analysisInfo.title" })}
              </StyledHeader>
              <StyledSubHeader>
                {formatMessage(
                  {
                    id: "homePage.content.analysisInfo.description",
                  },
                  { br: computer ? <br /> : " " }
                )}
              </StyledSubHeader>
            </div>
          </Grow>
          <Grow
            in={scrollPosition > (computer ? 2800 : 2000)}
            style={{ transformOrigin: "bottom center" }}
            timeout={1000}
          >
            <StyledCustomSwiperContainer>
              <Swiper
                navigation
                pagination={{ clickable: true, type: "bullets" }}
                centeredSlides={true}
              >
                <SwiperSlide>
                  <StyledCustomerListImg
                    src={require("assets/img/customerList.svg").default}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <StyledCustomerListImg
                    src={require("assets/img/marketList.svg").default}
                  />
                </SwiperSlide>
              </Swiper>
            </StyledCustomSwiperContainer>
          </Grow>
        </StyledField>
        <Slide
          direction="up"
          timeout={1000}
          in={scrollPosition > (computer ? 3700 : 2400)}
        >
          <div style={{ marginTop: computer ? "-123px" : "-24px" }}>
            <LinearLine />
            <StyledField computer={computer}>
              <Grid container style={{ marginTop: computer ? 61 : 0 }}>
                <Grid item xs={12} md={5}>
                  <StyledModuleField>
                    <StyledHeader
                      style={{ textAlign: computer ? "left" : "center" }}
                    >
                      {formatMessage({
                        id: "homePage.content.metacrmInfo.title",
                      })}
                    </StyledHeader>
                    <StyledSubHeader
                      style={{
                        marginTop: 18,
                        maxWidth: computer ? "393px" : "100%",
                      }}
                    >
                      {formatMessage({
                        id: "homePage.content.metacrmInfo.description",
                      })}
                    </StyledSubHeader>
                    <StyledLearnMoreButtonContainer
                      style={{ textAlign: computer ? "left" : "center" }}
                    >
                      <XLPrimaryButton
                        variant={VARIANT.OUTLINED}
                        onClick={() =>
                          window.open(
                            "https://www.youtube.com/watch?feature=shared&v=uLkr6D4kjmE"
                          )
                        }
                      >
                        {formatMessage({ id: "global.btn.watchOnYoutube" })}
                        <i
                          className="meta-crm-icon-ic_youtube-1 font-size-32"
                          style={{ marginLeft: 8 }}
                        />
                      </XLPrimaryButton>
                    </StyledLearnMoreButtonContainer>
                  </StyledModuleField>
                </Grid>
                <Grid item xs={12} md={7}>
                  <video
                    width="100%"
                    controls
                    style={{
                      objectFit: "cover",
                      height: computer ? "420px" : undefined,
                    }}
                    poster={require("assets/img/videoPreview.png")}
                  >
                    <source
                      src={require("assets/img/video.mp4")}
                      type="video/mp4"
                    />
                    {formatMessage({ id: "global.error.videoNotSupport" })}
                  </video>
                </Grid>
              </Grid>
            </StyledField>
          </div>
        </Slide>

        <StyledField computer={computer}>
          <Grow
            in={scrollPosition > (computer ? 4200 : 2600)}
            style={{ transformOrigin: "bottom center" }}
            timeout={2000}
          >
            <div>
              <StyledHeader>
                {formatMessage({
                  id: "homePage.content.integrationsInfo.title",
                })}
              </StyledHeader>
              <StyledSubHeader>
                {formatMessage(
                  {
                    id: "homePage.content.integrationsInfo.description",
                  },
                  { br: computer ? <br /> : " " }
                )}
              </StyledSubHeader>
            </div>
          </Grow>
          <StyledGlobalContainer computer={computer}>
            <Grow in={scrollPosition > (computer ? 4400 : 2800)} timeout={2000}>
              <StyledGlobalImg
                src={require("assets/img/global.png")}
                computer={computer}
              />
            </Grow>
            <Fade in={scrollPosition > (computer ? 4700 : 2800)} timeout={2000}>
              <StyledGlobalToken
                computer={computer}
                src={require("assets/img/globalTokens.svg").default}
              />
            </Fade>
          </StyledGlobalContainer>
        </StyledField>
      </StyledMainContainer>
      <StyledLandingFooterContainer computer={computer}>
        <Grow
          in={scrollPosition > (computer ? 5300 : 3300)}
          style={{ transformOrigin: "bottom center" }}
          timeout={1500}
        >
          <div>
            <StyledHeader>
              {formatMessage({ id: "homePage.content.useCaseInfo.title" })}
            </StyledHeader>
            <StyledSubHeader>
              {formatMessage({
                id: "homePage.content.useCaseInfo.description",
              })}
            </StyledSubHeader>
            <LandingOurPartners />
          </div>
        </Grow>
        <Grow
          in={scrollPosition > (computer ? 5600 : 3600)}
          style={{ transformOrigin: "bottom center" }}
          timeout={1500}
        >
          <StyledRecomandImg
            src={require(`assets/img/recomands${computer ? "" : "Mobile"}.png`)}
          />
        </Grow>
        <Grow
          in={scrollPosition > (computer ? 6200 : 4200)}
          style={{ transformOrigin: "bottom center" }}
          timeout={1000}
        >
          <StyledBanner>
            <StyledLeanerText>
              {formatMessage(
                { id: "homePage.footer.title" },
                { br: computer ? <br /> : " " }
              )}
            </StyledLeanerText>
            {computer && <br />}
            <StyledLeanerText></StyledLeanerText>
            <StyledStartButtonContainer
              style={{ marginTop: 35, marginBottom: 15 }}
            >
              <XLPrimaryButton onClick={openTrial}>
                {formatMessage({ id: "global.btn.getStarted" })}
              </XLPrimaryButton>
            </StyledStartButtonContainer>
          </StyledBanner>
        </Grow>
      </StyledLandingFooterContainer>
    </div>
  );
};

export default Home;
