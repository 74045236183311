import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";

import { useMediaQuery } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import IconIcMetaCRM from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcMetaCRM";

import { Fade, Grid, ClickAwayListener } from "@mui/material";
import { Link } from "react-router-dom";
import IconIcMetaform from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcMetaform";
import Box from "@mui/material/Box";
import _ from "lodash";

import {
  StyledLink,
  StyledPopover,
  StyledProduct,
  StyledProductHeader,
  StyledLinkPrimary,
  StyledSupportTag,
} from "./LandingHeader.styles";
import { PrimaryButton } from "components/CustomButtons/CustomButton.style";
import { IconButton, VARIANT } from "@metacrm/metacrm-material-ui/dist/Button";
import { OpenTrialContext } from "../pages/TrialDialog/OpenTrialContext";
import { useIntl } from "react-intl";
const drawerWidth = "80vw";

const useStyles = makeStyles((theme) => ({
  root: {},

  toolbarIcon: (props) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...props.mixins.toolbar,
  }),
  appBar: (props) => ({
    background: "rgba(255,255,255,0.4)",
    position: "fixed",
    boxShadow: "0px 0px 0px",
    zIndex: props.zIndex.drawer + 1,
    backdropFilter: "blur(4.650000095367432px)",
    borderBottom: `1px solid #E6E6E6`,
  }),
  appBarShift: (props) => ({
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: props.transitions.create(["width", "margin"], {
      easing: props.transitions.easing.sharp,
      duration: props.transitions.duration.enteringScreen,
    }),
  }),
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: (props) => ({
    background: "#FFFFFF",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: props.transitions.create("width", {
      easing: props.transitions.easing.sharp,
      duration: props.transitions.duration.enteringScreen,
    }),
  }),
  drawerPaperClose: (props) => ({
    overflowX: "hidden",
    transition: props.transitions.create("width", {
      easing: props.transitions.easing.sharp,
      duration: props.transitions.duration.leavingScreen,
    }),
    width: props.spacing(7),
    [props.breakpoints.up("sm")]: {
      width: props.spacing(9),
    },
  }),
  appBarSpacer: (props) => props.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: (props) => ({
    paddingTop: props.spacing(4),
    paddingBottom: props.spacing(4),
  }),
  paper: (props) => ({
    padding: props.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  }),
  fixedHeight: {
    height: 240,
  },
  gradientBtn: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    border: 0,
    borderRadius: 3,
    // boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    color: "white",
    height: 48,
    padding: "0 30px",
  },

  // toolbar: theme.mixins.toolbar,
}));

const LandingHeader = ({ extra, hidden }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();
  const customTheme = useTheme();
  const { formatMessage } = useIntl();
  const classes = useStyles(customTheme);
  const [openProducts, setOpenProducts] = useState(false);
  const { openTrial } = useContext(OpenTrialContext);
  const handleClick = (e) => {
    e.preventDefault();
    setOpenProducts(true);
  };
  const handleClose = () => {
    setOpenProducts(false);
  };

  const computer = useMediaQuery(customTheme.breakpoints.up("sm"));
  useEffect(() => {
    setMobileOpen(false);
  }, [location]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (hidden) {
    return <div />;
  }

  const renderProduct = ({ icon, title, desc, link, supports = [] }) => {
    return (
      <Grid item xs={12} md={6}>
        <StyledProduct to={link} onClick={() => setOpenProducts(false)}>
          <div>{icon}</div>
          <div className="productText">
            <div className="productTitle">{title}</div>
            <div className="productDesc">{desc}</div>
            {!_.isEmpty(supports) && (
              <Box display={"flex"} alignItems={"center"} mt={1}>
                <Box
                  sx={{ fontWeight: 400, fontSize: "12px" }}
                  color={(theme) => theme.customColors.grey[500]}
                  mr={1}
                >
                  {formatMessage({ id: "global.count.supported" })} :
                </Box>
                {supports.map((item, index) => (
                  <React.Fragment key={index}>{item}</React.Fragment>
                ))}
              </Box>
            )}
          </div>
          <Box
            display={"flex"}
            alignItems={"center"}
            ml={"auto"}
            color={(theme) => theme.customColors.purple[500]}
            sx={{
              position: "absolute",
              right: "2px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <i className="meta-crm-icon-ic_arrowLineRight font-size-24" />
          </Box>
        </StyledProduct>
      </Grid>
    );
  };

  const renderProductPopover = () => {
    return (
      openProducts && (
        <ClickAwayListener onClickAway={handleClose}>
          <Fade in={true} timeout={350}>
            <StyledPopover>
              <StyledProductHeader>
                {formatMessage({ id: "headline.header.products" })}
              </StyledProductHeader>
              <Grid container>
                {renderProduct({
                  icon: (
                    <Box
                      component={"img"}
                      className="productIcon"
                      src={require("assets/img/marketingIcon.svg").default}
                      alt="MetaCRM for Marketing"
                    />
                  ),
                  title: "MetaCRM for Marketing",
                  desc: "Improve user acquisition ,engagement, and retention by on-chain marketing attribution & personalized marketing.",
                  link: "/marketing",
                  supports: [
                    <StyledSupportTag
                      component="img"
                      src={require("assets/img/evm-tag.svg").default}
                    />,
                    <StyledSupportTag
                      component="img"
                      src={require("assets/img/solana-tag.svg").default}
                    />,
                  ],
                })}

                {renderProduct({
                  icon: (
                    <Box
                      component="img"
                      src={require("assets/img/metaFormSelect.png")}
                      className="productIcon"
                    />
                  ),
                  title: "MetaForm",
                  desc: "Blockchain-native survey tool that helps you understand users & streamline all marketing campaigns with automated airdrop capability.",
                  link: "https://metaform.xyz/",
                })}

                {renderProduct({
                  icon: (
                    <Box
                      component="img"
                      className="productIcon"
                      src={require("assets/img/serviceIcon.svg").default}
                      alt="MetaCRM for Service"
                    />
                  ),
                  title: "MetaCRM for Service",
                  desc: "Better serve user by integrating Al-powered automated workflow,cross-chain profiles, and interaction history while replying all inbound requests form users.",
                  link: "/service",
                  supports: [
                    <StyledSupportTag
                      component="img"
                      src={require("assets/img/evm-tag.svg").default}
                    />,
                    <StyledSupportTag
                      component="img"
                      src={require("assets/img/aptos-tag.svg").default}
                    />,
                  ],
                })}
              </Grid>
            </StyledPopover>
          </Fade>
        </ClickAwayListener>
      )
    );
  };
  const renderMobileDisplay = () => {
    return (
      <AppBar position="fixed" className={classes.appBar}>
        <Hidden mdUp>
          <Toolbar className={classes.toolbar}>
            <Grid justifyContent="space-between" alignItems="center" container>
              <Grid item>
                <Link to={"/"}>
                  <IconIcMetaCRM height={"40px"} width={"120px"} />
                </Link>
              </Grid>
              <Grid item></Grid>
              <Grid item>
                <IconButton
                  style={{ color: "#383838" }}
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                >
                  <i className="meta-crm-icon-ic_menu font-size-16" />
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </Hidden>
        <Hidden mdUp implementation="js">
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={mobileOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClose={handleDrawerToggle}
          >
            <Box
              sx={{
                textAlign: "center",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                padding="12px 20px"
                borderBottom={(theme) =>
                  `1px solid ${theme.customColors.grey[300]}`
                }
              >
                <IconIcMetaCRM height={"25px"} width={"150px"} />

                <IconButton
                  onClick={() => {
                    handleDrawerToggle();
                  }}
                  color={customTheme.customColors.grey[700]}
                >
                  <i className="meta-crm-icon-ic_cancel font-size-16" />
                </IconButton>
              </Box>

              <Box padding="15px 20px">
                <Box>
                  <Box
                    textAlign={"left"}
                    fontSize={"16px"}
                    color={(theme) => theme.customColors.purple[500]}
                    display={"flex"}
                  >
                    {formatMessage({ id: "headline.header.products" })}{" "}
                    <Box display={"flex"} ml="2px">
                      <i className="meta-crm-icon-ic_arrowLineUp font-size-16" />
                    </Box>
                  </Box>
                  <Box py={"15px"} pl="5px">
                    <Box
                      component={Link}
                      to="/marketing"
                      display={"flex"}
                      alignItems={"center"}
                      mb={2}
                    >
                      <Box
                        component={"img"}
                        src={require("assets/img/marketingIcon.svg").default}
                        mr="12px"
                        width={"25px"}
                        height={"25px"}
                      ></Box>
                      <Box
                        fontWeight={700}
                        color={(theme) => theme.customColors.grey[800]}
                        fontSize={"16px"}
                      >
                        {formatMessage({ id: "global.product.marketingPlan" })}
                      </Box>
                      <Box
                        ml="auto"
                        color={(theme) => theme.customColors.grey[500]}
                        display={"flex"}
                      >
                        <i className="meta-crm-icon-ic_arrowLineRight font-size-16" />
                      </Box>
                    </Box>

                    <Box
                      component={Link}
                      to="/service"
                      display={"flex"}
                      alignItems={"center"}
                      mb={2}
                    >
                      <Box
                        component={"img"}
                        src={require("assets/img/serviceIcon.svg").default}
                        mr="12px"
                        width={"25px"}
                        height={"25px"}
                      ></Box>
                      <Box
                        fontWeight={700}
                        color={(theme) => theme.customColors.grey[800]}
                        fontSize={"16px"}
                      >
                        {formatMessage({ id: "global.product.servicePlan" })}
                      </Box>

                      <Box
                        ml="auto"
                        color={(theme) => theme.customColors.grey[500]}
                        display={"flex"}
                      >
                        <i className="meta-crm-icon-ic_arrowLineRight font-size-16" />
                      </Box>
                    </Box>

                    <Box
                      component={Link}
                      display={"flex"}
                      alignItems={"center"}
                      to="https://metaform.xyz/"
                      target="_blank"
                      mb={2}
                    >
                      <Box mr="12px" width={"25px"} height={"25px"}>
                        <IconIcMetaform
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        ></IconIcMetaform>
                      </Box>

                      <Box
                        fontWeight={700}
                        color={(theme) => theme.customColors.grey[800]}
                        fontSize={"16px"}
                      >
                        {formatMessage({ id: "global.product.metaform" })}
                      </Box>

                      <Box
                        ml="auto"
                        color={(theme) => theme.customColors.grey[500]}
                        display={"flex"}
                      >
                        <i className="meta-crm-icon-ic_arrowLineRight font-size-16" />
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  component={Link}
                  to="https://blog.metacrm.inc"
                  display={"block"}
                  textAlign={"left"}
                  fontSize={"16px"}
                  color={(theme) => theme.customColors.grey[600]}
                >
                  Blog
                </Box>
              </Box>

              <Box mt="auto" pb="20px">
                <Box mb={2}>
                  <StyledLinkPrimary
                    onClick={() => {
                      window.open("https://app.metacrm.inc/workspace/login");
                    }}
                  >
                    {formatMessage({ id: "global.btn.logIn" })}
                  </StyledLinkPrimary>
                </Box>
                <Box mb={2}>
                  <PrimaryButton onClick={openTrial}>
                    {formatMessage({ id: "global.btn.getStarted" })}
                  </PrimaryButton>
                </Box>
                <Box>
                  <PrimaryButton
                    sx={{ padding: 0 }}
                    variant={VARIANT.OUTLINED}
                    onClick={() => {
                      window.open(
                        "https://metaform.xyz/user/633ba1665d7cde34a5658167"
                      );
                    }}
                  >
                    {formatMessage({ id: "global.btn.scheduleADemo" })}
                  </PrimaryButton>
                </Box>
              </Box>
            </Box>
          </Drawer>
        </Hidden>
      </AppBar>
    );
  };
  return (
    <div className={classes.root}>
      {renderMobileDisplay()}
      <Hidden smDown>
        <AppBar className={classes.appBar}>
          {renderProductPopover()}
          <Toolbar className={classes.toolbar}>
            <Grid
              justifyContent="space-between"
              alignItems="center"
              container
              style={{ margin: "0 auto", maxWidth: 1200 }}
            >
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Link
                  to={"/"}
                  style={{
                    marginRight: 65,
                    paddingBottom: 0,
                    display: "flex",
                  }}
                >
                  <IconIcMetaCRM height={"40px"} width={"150px"} />
                </Link>
                <StyledLink onClick={handleClick}>
                  {formatMessage({ id: "headline.header.products" })}
                  <i
                    className="meta-crm-icon-ic_arrowLineDown font-size-14"
                    style={{ padding: 4, paddingTop: 6 }}
                  />
                </StyledLink>
                {/* <StyledLink>Plans</StyledLink> */}
                <StyledLink to="https://blog.metacrm.inc">
                  {formatMessage({ id: "headline.header.blog " })}
                </StyledLink>
              </Grid>

              <Grid>
                <StyledLinkPrimary
                  onClick={() => {
                    window.open("https://app.metacrm.inc/workspace/login");
                  }}
                >
                  {formatMessage({ id: "global.btn.logIn" })}
                </StyledLinkPrimary>
                <PrimaryButton style={{ marginLeft: 20 }} onClick={openTrial}>
                  {formatMessage({ id: "global.btn.getStarted" })}
                </PrimaryButton>
                <PrimaryButton
                  variant={VARIANT.OUTLINED}
                  style={{ marginLeft: 16 }}
                  onClick={() => {
                    window.open(
                      "https://metaform.xyz/user/633ba1665d7cde34a5658167"
                    );
                  }}
                >
                  Schedule a Demo
                </PrimaryButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Hidden>
      <Box sx={{ marginBottom: computer ? "64px" : "56px" }} />
    </div>
  );
};

export default LandingHeader;
