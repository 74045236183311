import React, { useRef, useState, useEffect } from "react";
import { useTheme } from "@mui/styles";
import {
  Slide,
  Grid,
  useMediaQuery,
  Fade,
  Collapse,
  Grow,
} from "@mui/material";
import _ from "lodash";
import LandingOurPartners from "./LandingOurPartners/LandingOurPartners";
import { StyledLandingRevolutionContainer } from "../pages/Home.styles";
import { XLPrimaryButton } from "components/CustomButtons/CustomButton.style";
import IconIcMailchimp from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcMailchimp";
import SwiperCore, {
  Navigation,
  Pagination,
  Controller,
  Thumbs,
  Autoplay,
} from "swiper";
import "swiper/swiper-bundle.css";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";

const Revolution = ({
  scrollPosition,
  computer,
  title,
  description,
  image,
  growIn,
  background,
}) => {
  return (
    <StyledLandingRevolutionContainer background={background}>
      <Grow
        in={growIn}
        style={{ transformOrigin: "bottom center" }}
        timeout={1000}
      >
        <Box>
          <Box
            sx={{
              backgroundImage: `url(${image})`,
              backgroundSize: "contain",
              backgroundPosition: "right bottom",
              backgroundRepeat: "no-repeat",
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              height: "27vw",
              maxHeight: "400px",
            }}
          />
          <Box
            sx={{
              maxWidth: "1350px",
              margin: "0 auto",
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingTop: computer ? "180px" : "60px",
            }}
          >
            <Box
              sx={{
                maxWidth: "512px",
                paddingBottom: computer ? "63px" : "165px",
                position: "relative",
                zIndex: 2,
                minHeight: "300px",
              }}
            >
              <Box fontSize={"38px"} fontWeight="bold" mb={"34px"}>
                {title}
              </Box>
              <Box sx={{ fontSize: "20px", fontWeight: 400 }}>
                {description}
              </Box>
            </Box>
          </Box>
        </Box>
      </Grow>
    </StyledLandingRevolutionContainer>
  );
};

export default Revolution;
