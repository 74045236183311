import { createBrowserRouter, Navigate } from "react-router-dom";
import { App, Landing } from "features/home";
import {
  Home,
  PrivacyPolicy,
  Service,
  NotFound,
  Marketing,
} from "features/home/pages";

const route = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <NotFound />,
    children: [
      {
        element: <Landing />,
        children: [
          { index: true, element: <Home /> },
          { path: "service", element: <Service /> },
          { path: "marketing", element: <Marketing /> },
          { path: "privacy-policy", element: <PrivacyPolicy /> },
        ],
      },
    ],
  },
]);

export default route;
