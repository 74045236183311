import styled from "@emotion/styled/macro";
import isStyledPropsValid from "utils/isStyledPropsValid";
import Box from "@mui/material/Box";

import { Link } from "react-router-dom";

export const StyledLinkMobile = styled(Link, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "block",
  color: theme.customColors.black,
  marginBottom: "10px",

  "&:hover": {
    color: theme.customColors.purple[600],
  },
}));
export const StyledProductHeader = styled("div")(({ theme }) => ({
  color: theme.customColors.grey[700],
  fontSize: 28,
  fontWeight: 300,
  paddingBottom: 18,
  borderBottom: "1px solid #E6E6E6",
}));
export const StyledHeader = styled("div")(({ theme }) => ({
  color: theme.customColors.grey[700],
  fontSize: 24,
  fontWeight: 900,
  paddingBottom: 23,
  borderBottom: "1px solid #E6E6E6",
}));
export const StyledPopover = styled("div")(({ theme }) => ({
  background: "#FFFFFF",
  maxWidth: 1200,
  marginTop: 10,
  width: "100%",
  position: "fixed",
  left: "50%",
  transform: "translateX(-50%)",
  top: 40,
  boxShadow: "0px 3px 7px 0px rgba(0, 0, 0, 0.10)",
  borderRadius: "0px 0px 10px 10px",
  padding: "41px 49px",
}));

export const StyledProduct = styled(Link)(({ theme }) => ({
  padding: 22,
  display: "flex",
  minHeight: 125,
  justifyContent: "flex-start",
  alignItems: "stretch",
  marginTop: 21,
  position: "relative",
  "& .meta-crm-icon-ic_arrowLineRight": {
    display: "none",
  },
  "&:hover": {
    // boxShadow: "0px 3px 7px 0px rgba(0, 0, 0, 0.10)",
    outline: `1px solid ${theme.customColors.purple[500]}`,
    borderRadius: 5,
    "& .meta-crm-icon-ic_arrowLineRight": {
      display: "block",
    },
    ".productTitle": {
      color: theme.customColors.purple[600],
    },
  },
  ".productIcon": {
    width: 40,
    height: 40,
    fontSize: 26,
    marginRight: 16,
    color: theme.customColors.purple[600],
  },
  ".productTitle": {
    color: theme.customColors.grey[700],
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 8,
  },
  ".productDesc": {
    color: theme.customColors.grey[600],
    fontSize: 12,
    fontWeight: 400,
  },
}));

export const StyledLink = styled(Link, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: "#777",
  fontSize: 16,
  fontWeight: 400,
  marginRight: 40,
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  "&:hover": {
    color: theme.customColors.purple[600],
  },
}));

export const StyledLinkPrimary = styled(StyledLink, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  display: "inline-flex",
  margin: 0,
  color: theme.customColors.purple[500],
  fontWeight: 700,
  fontSize: 14,
}));

export const StyledTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: "#383838",
  opacity: 0.4,
  marginTop: 32,
}));
export const StyledMobileTitle = styled("div", {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  color: "#383838",
  opacity: 0.4,
  marginTop: 32,
  marginBottom: 8,
}));

export const StyledSupportTag = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme }) => ({
  height: "26px",
  marginRight: "8px",
}));
