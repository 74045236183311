import styled from "@emotion/styled/macro";
import { keyframes } from "@emotion/react";
import Box from "@mui/material/Box";
import isStyledPropsValid from "utils/isStyledPropsValid";

export const StyledLandingWelcomContainer = styled(Box)(
  ({ theme, computer }) => ({
    margin: "0 auto",
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${require("assets/img/landingHeader.png")})`,
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    position: "relative",
    zIndex: 1,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  })
);

export const StyledLandingFooterContainer = styled("div")(
  ({ theme, computer }) => ({
    margin: "0 auto",
    padding: 10,
    paddingBottom: 46,
    textAlign: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${require("assets/img/landingFooter.png")})`,
    backgroundSize: "contain",
    backgroundPosition: "bottom",
    position: "relative",
  })
);

export const StyledLeanerText = styled(Box)(({ theme }) => ({
  color: "transparent",
  background: `linear-gradient(100deg, #F960AE 1.29%, #564BAB 51.16%, #40748F 100%)`,
  backgroundClip: "text",
  fontSize: 36,
  fontWeight: 800,
  lineHeight: "160%",
  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
  },
}));

export const StyledServiceLeanerText = styled("span")(({ theme }) => ({
  color: "transparent",
  background: `linear-gradient(99deg, #0096FF 12.07%, #7F41CE 54.79%, #29E9A4 96.62%)`,
  backgroundClip: "text",
  fontSize: 38,
  fontWeight: 800,
  lineHeight: "160%",
  fontFamily: "Poppins",
}));

export const StyledMarketingLeanerText = styled("span")(({ theme }) => ({
  color: "transparent",
  background: `linear-gradient(100deg, #F960AE 1.29%, #564BAB 51.16%, #40748F 100%)`,
  backgroundClip: "text",
  fontSize: 38,
  fontWeight: 800,
  lineHeight: "160%",
  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
  },
}));

export const StyledSubTitle = styled("div")(({ theme }) => ({
  color: theme.customColors.grey[700],
  textAlign: "center",
  fontSize: 20,
  fontWeight: 500,
  lineHeight: "160%",
  marginTop: 10,

  [theme.breakpoints.down("md")]: {
    textAlign: "left",
    fontSize: 16,
  },
}));

export const StyledCloudContainer = styled("div")(({ theme }) => ({
  marginTop: 62,
  position: "relative",
}));
export const StyledCloud = styled("img")(({ theme }) => ({
  height: "330px",
  maxWidth: "80%",
  [theme.breakpoints.down("md")]: {
    height: "unset",
    maxWidth: "100%",
  },
}));
export const StyledLearnMoreButtonContainer = styled("div")(({ theme }) => ({
  marginTop: 46,
}));
export const StyledStartButtonContainer = styled(Box)(({ theme }) => ({
  marginTop: 50,
  marginBottom: 100,
}));
export const StyledCloudInfo1 = styled("img")(({ computer }) => ({
  height: computer ? "187px" : "100px",
  position: "absolute",
  top: computer ? "30%" : "0%",
  left: computer ? "20%" : "0%",
}));
export const StyledCloudInfo2 = styled("img")(({ computer }) => ({
  height: computer ? "242px" : "150px",
  position: "absolute",
  top: computer ? "15%" : "50%",
  right: computer ? "13%" : "0",
}));
export const StyledCloudInfo3 = styled("img")(({ computer }) => ({
  height: computer ? "80px" : "40px",
  position: "absolute",
  top: computer ? "5%" : "0%",
  left: computer ? "30%" : "30%",
}));

export const StyledCustomerListImg = styled("img")(({ theme }) => ({
  height: "597px",
  marginTop: 17,
  maxWidth: "100%",
  objectFit: "cover",
  objectPosition: "left",
  [theme.breakpoints.down("md")]: {
    height: "unset",
  },
}));

export const StyledRecomandImg = styled("img")(({ theme, computer }) => ({
  width: "100%",
  objectFit: "cover",
  objectPosition: "50% 50%",
  [theme.breakpoints.down("md")]: {
    paddingLeft: 40,
    paddingRight: 40,
  },
}));

export const StyledGlobalContainer = styled("div")(({ computer }) => ({
  marginTop: 33,
  position: "relative",
  marginBottom: computer ? 92 : 20,
}));
export const StyledGlobalImg = styled("img")(({ theme, computer }) => ({
  height: "579px",
  marginTop: computer ? 74 : 0,
  maxWidth: "100%",
  [theme.breakpoints.down("md")]: {
    height: "unset",
  },
}));
export const StyledGlobalToken = styled("img")(({ computer }) => ({
  width: computer ? "90%" : "140%",
  position: "absolute",
  top: computer ? "15%" : "18%",
  left: "5%",
}));

export const StyledModuleImg = styled("img")(({ theme, computer }) => ({
  height: "481px",
  maxWidth: "100%",
  objectFit: "contain",
  objectPosition: "left",

  [theme.breakpoints.down("md")]: {
    height: "230px",
  },
}));

export const StyledProductionIcon = styled("img")(({ theme }) => ({
  height: "66px",
  width: "66px",
  marginBottom: 18,
  marginTop: 18,
}));
export const StyledModuleIcon = styled("img")(({ theme }) => ({
  height: "66px",
  width: "66px",
  marginBottom: 18,
  marginTop: 18,
}));

export const StyledCloudCircle = styled("img")(({ theme }) => ({
  height: "370px",
  maxWidth: "100%",
  position: "absolute",
  top: "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}));

export const UnderLine = styled("span")(({ theme }) => ({
  textDecorationLine: "underline",
}));

export const StyledLinearLine = styled("img")(({ theme }) => ({
  height: 2,
  width: "100%",
}));

export const LinearLine = () => (
  <StyledLinearLine src={require("assets/img/line.svg").default} />
);

export const StyledBanner = styled("div")(({ theme }) => ({
  padding: "34px 0",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    paddingLeft: "10px",
    paddingRight: "10px",
  },
}));

export const StyledMainContainer = styled("div")(({ theme }) => ({
  backgroundRepeat: "no-repeat",
  padding: "0 20px",
  backgroundImage: `url(${require("assets/img/landingBackground.png")})`,
  backgroundSize: "cover",
}));

export const StyledField = styled(Box)(({ theme, computer, sx }) => ({
  padding: computer ? "110px 20px" : "50px 0px",
  margin: "0 auto",
  maxWidth: 1350,
  textAlign: "center",
  position: "relative",
  [theme.breakpoints.down("md")]: {
    paddingBottom: 10,
  },
  ...sx,
}));

export const StyledPreferredToolContainer = styled("div")(({ theme }) => ({
  padding: "57px 20px",
  background: "#FFFFFF",
  margin: "0 auto",
  maxWidth: 1300,
  marginTop: 100,
  textAlign: "center",
  position: "relative",
  borderRadius: "20px 20px 0 0",
  boxShadow: "0px 9.348px 18.696px 0px rgba(0,0,0,0.07)",
  [theme.breakpoints.down("md")]: {
    marginTop: 40,
    padding: "30px 20px 0",
  },
}));

export const StyledPreferredTool = styled("div")(({ theme, computer }) => ({
  fontSize: 30,
  fontWeight: 300,
  lineHeight: "120%",
  margin: "10px",
  position: "relative",

  "& svg": {
    marginBottom: 20,
  },

  "& div": {
    width: 156,
    margin: "0 auto",
  },
  "& img": {
    marginBottom: 20,
    width: 60,
    height: 60,
    [theme.breakpoints.down("md")]: {
      width: 46,
      height: 46,
    },
  },
  "& .comingSoon": {
    marginTop: 3,
    color: "#FF3296",
    fontSize: 15,
  },
  "& .comingSoonBlue": {
    marginTop: 3,
    color: "#0096FF",
    fontSize: 15,
  },
  margin: "0 auto",
  ...(computer && {
    "&:not(:last-child)::after": {
      content: '""',
      position: "absolute",
      right: "-80px",
      top: "40%",
      transform: "translateY(-50%)",
      height: "87px",
      width: "1px",
      backgroundColor: theme.customColors.grey[300],
    },
  }),

  [theme.breakpoints.down("md")]: {
    fontSize: 16,
    marginBottom: "30px",
  },
}));

export const StyledToolField = styled("div")(({ theme }) => ({
  padding: "45px 10px",
  margin: "0 auto",
  position: "relative",

  [theme.breakpoints.down("md")]: {
    paddingTop: 20,
    paddingBottom: 0,
  },
}));

export const StyledToolIcons = styled("img")(({ theme }) => ({
  // height: 186,
  maxWidth: "173px",
  objectFit: "contain",
  background: "white",
  position: "relative",
  margin: 10,

  [theme.breakpoints.down("md")]: {
    maxWidth: "130px",
  },
  [theme.breakpoints.down("sm")]: {
    maxWidth: "90px",
  },
}));

export const StyledChatIcon = styled("img")(({ theme, computer }) => ({
  height: 118,
  width: 191,
  position: "absolute",
  top: computer ? "5%" : "-25px",
  left: computer ? "28%" : "-7px",

  [theme.breakpoints.down("md")]: {
    height: 99,
    width: 130,
  },
}));
export const StyledIdealIcon = styled("img")(({ computer }) => ({
  height: 94,
  width: 72,
  position: "absolute",
  top: "10%",
  right: computer ? "38%" : "0",
}));

export const StyledBigAlpacaContainer = styled("div")(({ theme }) => ({
  marginTop: 28,
  position: "relative",
  marginBottom: 15,
}));

export const LinearLoadingContainer = styled("div")(({ theme }) => ({
  width: "100%",
  position: "absolute",
  top: 170,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledBigAlpaca = styled("img")(({ theme }) => ({
  height: 281,
  width: 281,
  maxWidth: "100%",

  [theme.breakpoints.down("md")]: {
    height: 160,
    width: 160,
  },
}));

export const StyledModuleField = styled("div")(({ theme }) => ({
  padding: "45px 10px",
  margin: "0 auto",
  textAlign: "left",

  [theme.breakpoints.down("md")]: {
    paddingTop: 0,
  },
}));

export const StyledHeader = styled(Box)(({ theme }) => ({
  fontSize: 38,
  fontWeight: 800,
  color: theme.customColors.grey[800],
  lineHeight: "160%",

  [theme.breakpoints.down("md")]: {
    fontSize: 20,
  },
}));
export const StyledSubHeader = styled("div")(({ theme }) => ({
  fontSize: 20,
  fontWeight: 400,
  color: theme.customColors.grey[700],
  marginTop: 4,
  lineHeight: "160%",
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
    fontSize: 14,
  },
}));

const blow = keyframes({
  "0%": {
    transform: "translate(-50%, -50%) scale(0.1)",
    opacity: 0,
  },

  "10%": {
    transform: "translate(-50%, -50%)",
    opacity: 0.6,
  },

  "100%": {
    transform: "translate(-50%, -70vh)",
    opacity: 0,
  },
});

export const StyledBubble = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, left, bottom, size, time, backgroundColor }) => {
  return {
    borderRadius: "50%",
    position: "absolute",
    pointerEvents: "none",
    transform: "translate(-50%, -50%)",
    animation: `${blow} ${time}s ease-in infinite`,
    left: `${left}%`,
    bottom: `${bottom}px`,
    width: `${size}px`,
    height: `${size}px`,
    background: backgroundColor,
    zIndex: -1,
  };
});

export const StyledLandingRevolutionContainer = styled(Box, {
  shouldForwardProp: isStyledPropsValid,
})(({ theme, background }) => {
  return {
    position: "relative",
    background: background,
  };
});
