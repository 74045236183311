import React, { useEffect, useRef } from "react";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled/macro";

const LinearLoading = ({ setLinearLoading }) => {
  const pathRef = useRef(null);
  const handleAnimationEnd = () => {
    setLinearLoading(true);
  };
  useEffect(() => {
    const pathElement = pathRef.current;
    pathElement.addEventListener("animationend", handleAnimationEnd);
    return () => {
      pathElement.removeEventListener("animationend", handleAnimationEnd);
    };
  }, []);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="434"
      viewBox="0 0 1024 434"
      fill="none"
    >
      <Path
        ref={pathRef}
        d="M140.5 432.5H11C5.47715 432.5 1 428.023 1 422.5V11C1 5.47717 5.47715 1 11 1H1011.5C1017.02 1 1021.5 5.47715 1021.5 11V422.5C1021.5 428.023 1017.02 432.5 1011.5 432.5H881"
        stroke="url(#paint0_linear_21058_69909)"
        stroke-width="1.8"
      />
      <defs>
        <linearGradient
          id="paint0_linear_21058_69909"
          x1="-23.1476"
          y1="161.31"
          x2="1021.5"
          y2="161.31"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#31B4FB" />
          <stop offset="0.239583" stop-color="#AD4CF9" />
          <stop offset="0.552083" stop-color="#FFE954" />
          <stop offset="1" stop-color="#A2F1F1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const draw = keyframes`
    to {
        stroke-dashoffset: 0;
    }
`;
const PATH_LENGTH = 2250;
export const Path = styled("path")(({}) => ({
  strokeDasharray: PATH_LENGTH,
  strokeDashoffset: PATH_LENGTH,
  animation: `${draw} 2s forwards`,
}));

export default LinearLoading;
