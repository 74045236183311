import React, { useRef, useState, useEffect, useCallback, memo } from "react";
import { StyledBubble } from "./Home.styles";

const colorArray = [
  `linear-gradient(160.25deg, rgba(65, 130, 255, 0.5) 8.97%, rgba(206, 217, 255, 0) 91.96%, #FFFFFF 91.96%)`,
  `linear-gradient(155.97deg, rgba(125, 239, 255, 0.5) 8.02%, #FFFFFF 92.14%)`,
  `linear-gradient(157.87deg, rgba(159, 125, 255, 0.5) 7.52%, #FFFFFF 91%)`,
];

const BubbleAnimation = () => {
  const randomLeft = useCallback(() => {
    return Math.floor(Math.random() * 100);
  }, []);

  const randomBottom = useCallback(() => {
    return Math.floor(Math.random() * 50);
  }, []);

  const randomSize = useCallback(() => {
    return Math.floor(Math.random() * 181) + 20;
  }, []);

  const randomAnimationTime = useCallback(() => {
    return Math.floor(Math.random() * 10) + 10;
  }, []);

  const randomColor = useCallback(() => {
    const randNum = Math.floor(Math.random() * colorArray.length);
    return colorArray[randNum];
  }, []);

  return (
    <StyledBubble
      left={randomLeft()}
      bottom={randomBottom()}
      size={randomSize()}
      time={randomAnimationTime()}
      backgroundColor={randomColor()}
    ></StyledBubble>
  );
};

export default memo(BubbleAnimation);
