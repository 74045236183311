import React, { useLayoutEffect, useContext } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";

import { Outlet, useLocation } from "react-router-dom";
import ColorModeContext from "@metacrm/metacrm-material-ui/dist/contexts/ColorMode.context";
import useColorMode from "@metacrm/metacrm-material-ui/dist/hooks/useColorMode.hooks";
import { OpenTrialProvider } from "./pages/TrialDialog/OpenTrialContext";

export default function App() {
  const { colorModeHooksValue, theme } = useColorMode();
  function Wrapper(props) {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return props.children;
  }

  return (
    <Wrapper>
      <ColorModeContext.Provider value={colorModeHooksValue}>
        <ThemeProvider theme={theme}>
          <OpenTrialProvider>
            <CssBaseline />
            <Outlet />
          </OpenTrialProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </Wrapper>
  );
}
