import styled from "styled-components";
import { withTheme } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

export const StyledOurPartnersTitle = withTheme(styled.div`
  font-size: ${(props) => {
    return props.computer ? "36px" : "30px";
  }};
  text-align: center;
  font-weight: 700;
  margin-bottom: 90px;
  position: relative;
  ${(props) => props.theme.breakpoints.down("xs")} {
    margin-bottom: 54px;
  }
  & .title-text {
    display: inline-block;
    position: relative;
    z-index: 1;
    padding: 0 24px;

    ${(props) => props.theme.breakpoints.up("md")} {
      &:before {
        content: "";
        position: absolute;
        height: 2px;
        width: 120px;
        top: 50%;
        left: -118px;
        background-color: #a5a5a5;
      }

      &:after {
        content: "";
        position: absolute;
        height: 2px;
        width: 120px;
        top: 50%;
        right: -118px;
        background-color: #a5a5a5;
      }
    }
  }
  & .title-line {
    ${(props) =>
      !props.computer && {
        position: "absolute",
        "background-color": "#a5a5a5",
        top: "unset",
        bottom: "-14px",
        height: "1px",
        width: "72px",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }};
  }
`);

export const StyledCustomSwiperContainer = withTheme(styled.div`
  & .swiper-container {
    padding-top: 20px;
    margin-top: 26px;
  }

  & .swiper-wrapper {
    padding-left: 0;
  }

  & .swiper-pagination-bullet-active {
    background: #7b61ff;
  }

  & .swiper-button-prev,
  & .swiper-button-next {
    &:hover {
      &:after {
        color: #7b61ff;
      }
    }
    &:after {
      color: #555555;
      font-size: 40px;
      font-weight: bold;
    }

    &.swiper-button-disabled {
      display: none;
    }
  }

  & .swiper-pagination-bullets {
    bottom: 620px;
  }
`);

export const StyledPartnerSliderContainer = withTheme(styled.div`
  padding: 64px 10% 56px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  min-height: 490px;

  ${(props) => props.theme.breakpoints.down("md")} {
    padding: 64px 6% 56px;
    min-height: 550px;
  }

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 32px 16px 56px;
    min-height: 535px;
  }
`);

export const StyledPartnerSliderLogo = styled.div`
  max-width: 43%;
  margin-bottom: 24px;

  & img {
    width: 100%;
  }
`;

export const StyledPartnerSliderLogoUnderLine = styled.div`
  width: 30px;
  height: 1px;
  background-color: #b6b6b6;
  margin-bottom: 24px;
`;

export const StyledPartnerDescribe = withTheme(styled.div`
  line-height: 28px;
  text-align: center;
  color: #555555;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 24px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    font-size: 16px;
    line-height: 22px;
  }
`);

export const StyledPartnerBossArea = styled.div`
  display: flex;
  text-align: left;
`;

export const StyledPartnerBossImage = styled.div`
  display: flex;
  height: 46px;
  width: 46px;
  border-radius: 50%;
  margin-right: 8px;
  & img {
    width: 100%;
    height: 100%;
  }
`;

export const StyledPartnerBossInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  & .partner-boss-title {
    font-size: 12px;
    color: #a5a5a5;
    margin-bottom: 5px;
  }
  & .partner-name {
    font-size: 16px;
    font-weight: 500;
    color: #555;
  }
`;

export const StyledGrid = withTheme(styled(Grid)`
  text-align: center;
  padding: 21px 0;
  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 0px 0;
  }
  img {
    width: 112px;
    height: 112px;
    ${(props) => props.theme.breakpoints.down("sm")} {
      width: 64px;
      height: 64px;
    }
  }
`);

export const StylePartnersIconArea = withTheme(styled.div`
  margin-bottom: 60px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    margin-bottom: 60px;
  }
`);

export const StylePartnersIconComputerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .MuiGrid-container {
    max-width: 937px;
    width: 64%;
  }
`;

export const StylePartnersIcon = withTheme(styled.div`
  width: 80px;
  height: 80px;
  margin-right: 30px;
`);

export const StylePartnersIconSwipe = withTheme(styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
  & .swiper-container {
    padding-bottom: 20px;
    ${"" /* max-width: ${(props) => props.maxWidth}; */}
  }

  & .swiper-wrapper {
    padding-left: 0;
    ${"" /* max-width: ${(props) => props.maxWidth}; */}
  }

  & .swiper-slide {
    display: flex;
    justify-content: center;
    .MuiGrid-container {
      max-width: 298px;
    }
  }

  & .swiper-button-prev {
    left: 17%;
    ${(props) => props.theme.breakpoints.down("sm")} {
      left: 23%;
    }
    ${(props) => props.theme.breakpoints.down("xs")} {
      left: 0%;
    }
  }

  & .swiper-button-next {
    right: 17%;
    ${(props) => props.theme.breakpoints.down("sm")} {
      right: 23%;
    }
    ${(props) => props.theme.breakpoints.down("xs")} {
      right: 0%;
    }
  }

  & .swiper-button-prev,
  & .swiper-button-next {
    width: 50px;

    &:hover {
      &:after {
        color: #7b61ff;
      }
    }
    &:after {
      color: #555555;
      font-size: 27px;
      font-weight: bold;
    }
  }

  & .swiper-pagination-bullets {
    bottom: -2px;
  }
`);
