import React from "react";

import LandingHeader from "./components/LandingHeader";
import SocialIcons from "./components/socialIcons/SocialIcons";

import _ from "lodash";
import Footer from "./components/Footer/Footer";
import { Outlet } from "react-router-dom";

const Landing = () => {
  return (
    <div style={{ color: "#383538" }}>
      <LandingHeader />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Landing;
