import React from "react";
import _ from "lodash";
import { useIntl } from "react-intl";

const NotFound = ({}) => {
  const { formatMessage } = useIntl();
  return (
    <div
      className="loadingPaper"
      style={{
        background: "white",
        textAlign: "center",
        height: "100vh",
        color: "#858585",
      }}
    >
      <div>
        <img src={require("assets/img/404.svg").default} />
        <div
          style={{
            marginTop: 32,
            marginBottom: 20,
            fontSize: 36,
            fontWeight: 800,
          }}
        >
          {formatMessage({ id: "notFoundPage.header.title" })}
        </div>
        <div style={{ fontWeight: 500, fontSize: 18 }}>
          {formatMessage({ id: "notFoundPage.header.description.line1" })},
          <br />{" "}
          {formatMessage({ id: "notFoundPage.header.description.line2" })}
        </div>
      </div>
    </div>
  );
};

export default NotFound;
