import React, { useMemo } from "react";

import { StylePartnersIconArea } from "./partner.styles";
import Marquee from "../Marquee";

const partners = [
  "parner_ic_1",
  "parner_ic_2",
  "parner_ic_3",
  "parner_ic_4",
  "parner_ic_5",
  "parner_ic_6",
  "parner_ic_7",
  "parner_ic_8",
  "parner_ic_9",
  "parner_ic_10",
  "parner_ic_11",
  "parner_ic_12",
  "parner_ic_13",
  "parner_ic_14",
  "parner_ic_15",
  "parner_ic_16",
  "parner_ic_17",
  "parner_ic_18",
  "parner_ic_19",
  "parner_ic_20",
  "parner_ic_21",
  "parner_ic_22",
  "parner_ic_23",
  "parner_ic_24",
  "parner_ic_25",
  "parner_ic_26",
  "parner_ic_27",
  "parner_ic_28",
  "parner_ic_29",
  "parner_ic_30",
  "parner_ic_31",
  "parner_ic_32",
  "parner_ic_33",
  "parner_ic_34",
  "parner_ic_35",
  "parner_ic_36",
  "parner_ic_37",
  "parner_ic_38",
  "parner_ic_39",
  "parner_ic_40",
  "parner_ic_41",
  "parner_ic_42",
];

const LandingOurPartners = () => {
  return (
    <StylePartnersIconArea>
      <Marquee
        images={partners.map((partner, idx) =>
          require(`assets/img/parner_ic/${partner}.svg`)
        )}
      />
    </StylePartnersIconArea>
  );
};

export default LandingOurPartners;
