import React from "react";
import SocialIcons from "../socialIcons/SocialIcons";
import _ from "lodash";

import FooterEmail from "./FooterEmail";
import { ReactComponent as EmailIcon } from "assets/img/ic_email.svg";
import IconIcMetaCRM from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconIcMetaCRM";
import IconLogoCRM from "@metacrm/metacrm-svg/dist/SvgIcon/svg-icons/IconLogoCRM";
import { StyledLink, StyledTitle } from "./Footer.styles";
import Grid from "@metacrm/metacrm-material-ui/dist/Grid";
import Box from "@mui/material/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  background: {
    background: "#FFFFFF",
    padding: "31px 15px",
  },
}));

const Footer = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const computer = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <div className={classes.background}>
      <div style={{ maxWidth: 1200, margin: "0px auto" }}>
        {computer ? (
          <div>
            <IconIcMetaCRM height={"40px"} width={"150px"} />
          </div>
        ) : (
          <Box display={"flex"} justifyContent={"center"}>
            <IconLogoCRM width={50} height={"unset"}></IconLogoCRM>{" "}
          </Box>
        )}
        <Grid
          container
          alignItems="flex-start"
          style={{
            marginBottom: computer ? 40 : 0,
            fontWeight: 400,
            marginTop: "-18px",
            fontSize: computer ? 15 : 12,
          }}
        >
          <Grid item xs={12} md={6}>
            <StyledTitle>
              {formatMessage({ id: "headline.footer.resources" })}{" "}
              <div className={classes.line} />
            </StyledTitle>
            <StyledLink
              target="_blank"
              noMarginLeft
              to="https://metacrm.gitbook.io/metacrm/"
            >
              {formatMessage({ id: "href.footer.documentation" })}
            </StyledLink>
            <StyledLink to="/privacy-policy" className="no-line">
              {formatMessage({ id: "href.footer.terms&privacy" })}
            </StyledLink>

            <StyledTitle style={{ marginBottom: computer ? 35 : 21 }}>
              {formatMessage({ id: "headline.footer.products" })}{" "}
              <div className={classes.line} />
            </StyledTitle>
            <StyledLink target="_blank" noMarginLeft to="/marketing">
              {formatMessage({ id: "global.product.marketingPlan" })}
            </StyledLink>
            <StyledLink target="_blank" to="/service">
              {formatMessage({ id: "global.product.servicePlan" })}
            </StyledLink>
            <StyledLink
              to="https://metaform.xyz/"
              target="_blank"
              className="no-line"
            >
              {formatMessage({ id: "global.product.metaform" })}
            </StyledLink>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ textAlign: computer ? "right" : "left" }}
            sx={{
              display: "flex",
              justifyContent: computer ? "flex-end" : "flex-start",
            }}
          >
            <Box>
              <StyledTitle>
                {formatMessage({ id: "headline.footer.contactUs " })}{" "}
                <div className={classes.line} />
              </StyledTitle>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <StyledLink
                  to="https://metaform.xyz/user/651449e47e72ae2219cbaa94"
                  target="_blank"
                  computer={computer}
                >
                  {formatMessage({ id: "href.footer.partnership" })}
                </StyledLink>
                <FooterEmail Icon={EmailIcon} computer={computer} ml={3} />
              </Box>

              <StyledTitle>
                {formatMessage({ id: "headline.footer.followUs" })}{" "}
                <div className={classes.line} />
              </StyledTitle>
              <SocialIcons white="true" />
            </Box>
          </Grid>
        </Grid>
        <div
          style={{
            color: "#555",
            opacity: 0.4,
            textAlign: "center",
            borderTop: "1px solid #e6e6e6",
            paddingTop: 40,
          }}
        >
          Copyright© 2023 - MetaCRM. All Rights Reserved
        </div>
      </div>
    </div>
  );
};

export default Footer;
