import React from "react";

import {
  StyledPrivacyPolicyPageContainer,
  StyledPrivacyPolicyPageTitle,
  StyledPrivacyPolicyContentContainer,
  StyledPrimaryList,
  StyledPrimaryListTitle,
  StyledPrimaryTitleOrder,
  StyledSecondaryListContainer,
  StyledSecondaryList,
  StyledSingleSecondaryContainer,
  StyledSingleSecondaryOrder,
  StyledSingleSecondaryContent,
} from "./PrivacyPolicy.styles";

const privacyPolicyContent = [
  {
    title: "Introduction",
    contentList: [
      `This policy (“Policy”) describes how MetaCRM Inc., a company established under the laws of the Cayman Islands, with its registered office at Oleander Way, 802 West Bay Road, PO Box 32052, Grand Cayman, KY1-1208, Cayman Islands (“MetaCRM”), collects, uses, and shares information about you when you use MetaCRM’s software solutions (the “Services”).`,
      `It is important that you read and understand this Policy before using any of the Services. Use of the Services constitutes your agreement to this Policy in its entirety. If you do not agree to any portion of this Policy, please do not use the Services.`,
    ],
  },
  {
    title: "Collection, Use, and Disclosure",
    contentList: [
      `We may collect personal information about you from various sources, including the Services, or when you call or email us or otherwise communicate with us.
      \n
      We may use collected information to: administer your account (where applicable); verify your identity; process your registration as a user; provide you with customer service and respond to your queries, feedback, claims or disputes; enhance and personalize your experience with us; communicate with you about products, services and events that may be of interest to you (or for other promotional purposes); or make such disclosures as may be required for any of the above purposes or other purposes described in this Policy.`,

      `By providing personal information to MetaCRM, you are consenting to MetaCRM to collect, use, and disclose the information provided and to share the same with our affiliates, business partners, and other third parties for one or more of the purposes for which the information was collected or to be used or disclosed, and other reasonably related purposes as provided in this Policy. We also may share personal information with our service providers who perform services on our behalf under data processing or service agreements. These service providers are not authorized by us to use or disclose the information except as necessary to perform services on our behalf or comply with legal requirements.
      \n
      We also may disclose information about you (i) if we are required to do so by law or legal process (such as a court order), (ii) in response to a request by law enforcement authorities, or (iii) when we believe disclosure is necessary or appropriate to prevent physical harm or financial loss or in connection with an investigation of suspected or actual illegal activity.
      \n
      We also reserve the right to transfer personal information we have about you in the event we sell, merge or transfer all or a portion of our business or assets. Following such a sale, merger or transfer, you may contact the entity to which we transferred your personal information with any inquiries concerning the processing of that information.
      `,
      `By providing personal information to MetaCRM, you are consenting to MetaCRM to collect, use, and disclose the information provided and to share the same with our affiliates, business partners, and other third parties for one or more of the purposes for which the information was collected or to be used or disclosed, and other reasonably related purposes as provided in this Policy. We also may share personal information with our service providers who perform services on our behalf under data processing or service agreements. These service providers are not authorized by us to use or disclose the information except as necessary to perform services on our behalf or comply with legal requirements.
      `,
    ],
  },
  {
    title: "Collection, Use, and Disclosure",
    contentList: [
      `You can access and update certain personal information about you by visiting your account settings. You can also submit a data privacy request to receive a copy of personal information that we hold about you, free of charge.
      We may use collected information to: administer your account (where applicable); verify your identity; process your registration as a user; provide you with customer service and respond to your queries, feedback, claims or disputes; enhance and personalize your experience with us; communicate with you about products, services and events that may be of interest to you (or for other promotional purposes); or make such disclosures as may be required for any of the above purposes or other purposes described in this Policy.
      \n
      If you believe any personal information we have on record relating to you is incorrect or incomplete, we will work with you to make corrections as necessary. Note that we may not accommodate a request to update information if we believe such update would violate any law or legal requirement, or would render such information to be incorrect.
      `,
      `If you would like MetaCRM to delete your account or personal information, you can submit a deletion request. Note that when we delete your account, personal information that you have shared with others (for example, through comments or an intake form) may still be visible. MetaCRM will also retain certain personal information about you where reasonably necessary to comply with our legal obligations or for our legitimate business purposes.`,
    ],
  },
  {
    title: "Third Parties",
    contentList: [
      `This document only addresses the use and disclosure of personal information MetaCRM collects from you. To the extent you disclose your personal information to other parties through the Services, different rules may apply to how they use and share your personal information. Accordingly, you will need to look at the privacy policies of these third parties to understand their data privacy practices.
      `,
    ],
  },
  {
    title: "Personal Information of Others",
    contentList: [
      `To the extent the Services involve personal data (“Third Party Data”) of others that you have provided to MetaCRM, you agree that you have provided notice and have obtained all consents and rights necessary under all applicable laws, rules, and regulations for MetaCRM to process or otherwise use such Third Party Data in connection with the Services.
    `,
      `To the extent the Services involve personal data (“Third Party Data”) of others that you have provided to MetaCRM, you agree that you have provided notice and have obtained all consents and rights necessary under all applicable laws, rules, and regulations for MetaCRM to process or otherwise use such Third Party Data in connection with the Services.
      \n
      You may request that we delete, cease processing or use of, or update any Third Party Data previously provided.
      `,
      `You agree to comply with all applicable laws, rules, and regulations with respect to the Third Party Data.
      \n
      You also agree that (i) MetaCRM is entitled to process Third Party Data for any purpose reasonably connected with the Services, (ii) you have taken, and will take from time to time as needed, all action required by applicable laws, rules, and regulations to permit or grant consent necessary for the processing of Third Party Data by MetaCRM, (iii) as far as you are aware, MetaCRM’s processing of Third Party Data in connection with the Services will not cause MetaCRM to breach any laws, rules, or regulations, and (iv) any Third Party Data provided to MetaCRM is accurate and up-to-date.
      \n
      You agree to hold harmless and indemnify MetaCRM, its affiliates, and their respective employees, directors, or contractors for any damages, costs, and expenses resulting directly or indirectly from your non-compliance with this Section 5.3.
      `,
    ],
  },
  {
    title: "Governing Law and Venue",
    contentList: [
      `The laws of the Cayman Islands shall govern this Policy and your use of the Services. You agree to submit to the exclusive jurisdiction of the courts of Cayman Islands.
      `,
    ],
  },
  {
    title: "Changes; Severability",
    contentList: [
      `We may make changes to this Policy from time to time, with or without prior notice to you. It is your responsibility to keep abreast of changes to this Policy.
      `,
      `If part of this Policy cannot be enforced, the remainder of this Policy will still apply to our relationship.`,
    ],
  },
];
const PrivacyPolicy = () => {
  return (
    <>
      <StyledPrivacyPolicyPageContainer>
        <StyledPrivacyPolicyPageTitle>
          Terms and Privacy
        </StyledPrivacyPolicyPageTitle>
        <StyledPrivacyPolicyContentContainer>
          {privacyPolicyContent.map((privacyPolicy, index) => (
            <StyledPrimaryList key={index}>
              <StyledPrimaryListTitle>
                <StyledPrimaryTitleOrder>{index + 1}. </StyledPrimaryTitleOrder>
                {privacyPolicy.title}
              </StyledPrimaryListTitle>
              <StyledSecondaryListContainer>
                {privacyPolicy.contentList.map(
                  (privacyTextContent, contentIndex) => (
                    <StyledSecondaryList key={contentIndex}>
                      <StyledSingleSecondaryContainer>
                        <StyledSingleSecondaryOrder>
                          {index + 1}.{contentIndex + 1}
                        </StyledSingleSecondaryOrder>
                        <StyledSingleSecondaryContent>
                          {privacyTextContent}
                        </StyledSingleSecondaryContent>
                      </StyledSingleSecondaryContainer>
                    </StyledSecondaryList>
                  )
                )}
              </StyledSecondaryListContainer>
            </StyledPrimaryList>
          ))}
        </StyledPrivacyPolicyContentContainer>
      </StyledPrivacyPolicyPageContainer>
    </>
  );
};

export default PrivacyPolicy;
