import React from "react";
import styled from "@emotion/styled/macro";
import { Button } from "@metacrm/metacrm-material-ui/dist/Button";

export const PrimaryButton = styled(Button)(({ theme }) => ({
  borderRadius: "40px",
  minWidth: 156,
  fontWeight: 700,
  fontSize: 14,
  boxShadow: "1px 1px 0px 0px rgba(0, 0, 0, 0.10)",
}));

export const XLPrimaryButton = styled(Button)(({ theme }) => ({
  borderRadius: "40px",
  minWidth: 250,
  fontWeight: 700,
  height: 62,
  fontSize: 20,
  boxShadow: "1px 1px 0px 0px rgba(0, 0, 0, 0.10)",
  [theme.breakpoints.down("md")]: {
    minWidth: 156,
    height: 36,
    fontSize: "14px",
  },
}));
